@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'Archivo';
}
html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}
a{
  color: #091E42;
}
@font-face {
  font-family:"clash-g-bold";
  src: url("../fonts/ClashGrotesk_Complete/Fonts/WEB/fonts/ClashGrotesk-Bold.ttf");
}
input:focus,textarea:focus{
  background: #E6F0FF !important;
  border: 1px solid #003E9C !important;
  outline: none;
}
tr{
  cursor:pointer
}
.mainTitle{
  font-family:"clash-g-bold";
  color: #091E42;
  font-size: 2.5em;
  font-weight: 700;
}
.mainBtn{
  background: #0047B3;
border: 2px solid #0047B3;
border-radius: 8px;
text-decoration: none !important;
transition: all ease-in-out 200ms;
padding: 0.5em 2em !important;
display: inline-block;
color: #E6F0FF !important;
height: fit-content;
}
.mainBtn:hover{
  transform: scale(0.9);
}
/* login page */
.loginPage{
  padding-top: 1em;
}
.loginPage form{
  background: #F6F6F6;
  border: 1px solid #006BA7;
  border-radius: 10px;
  padding: 5em 3em 15em 3em;
  max-height: 85vh;
}
.loginPage form .mainBtn{
  margin-top: 3em;
  padding: 0.5em 7em !important;
}
.loginPage form label{
  font-family: 'Archivo', sans-serif;
  color: #091E42;
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}
.loginPage form input:not(input[type="checkbox"]){
  display: block;
  width: 100%;
  background: #FDFDFD;
border: 1px solid #091E42;
border-radius: 12px;
padding: 1.5em 1em;
padding-right: 5em;
color: #7A8699;
font-size: 1em;
margin-bottom: 3em;
}
.loginPage form .passwordBox{
  position: relative;
}
.loginPage form .passwordBox img{
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.loginPage form .forgot{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loginPage form .forgot a{
  text-decoration: none;
  color: #0047B3;
  font-weight: 400;
  font-family: 'Archivo', sans-serif;
  transition: all ease-in-out 200ms;
}
.loginPage form .forgot a:hover{
  opacity: 0.7;
}
/* sidebar  */
.sideBar{
  width: 299px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
  background-color: #ffff;
  min-height: 100vh;
  z-index: 9;
}
.sideBar .mainTitle{
  text-align: center;
  padding: 0.46em 0;
  border-bottom: 1px solid #B0B0B0;
}
.sideBar .sideMenu{
  padding: 2em 0em;
}
.sideBar .sideMenu a{
  display: block;
padding: 1.2em 0.6em;
text-decoration: none;
color: #091E42;
font-size: 1.2em;
font-weight: 600;
padding-left: 1em;
}
.sideBar .sideMenu a:hover{
  background-color: transparent !important;
  color: #003E9C !important;
}
.sideBar .sideMenu a img{
  margin-right: 0.7em;
}
.sideBar .sideMenu .card a{
  font-weight: 400;
  transition: all ease-in-out 200ms;
}
.sideBar .sideMenu .card a:hover{
  background-color: #BDE4B5;

}
.sideBar .sideMenu  a.inviteTeam{
  border-top: 1px solid #8F8F8F;;
  border-bottom: 1px solid #8F8F8F;;
}
.sideBar .sideMenu .card{
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  padding-left: 2em;
}
.sideCollapes{
  position: relative;
}
.sideCollapes .arrowdown{
  position: absolute;
  right: -7px;
  top: 50%;
  transform: translateY(-50%);
transition: all ease-in-out 200ms;
}
.sideBar a[aria-expanded="true"] .arrowdown{
  transform: translateY(-50%) rotate(180deg);
}
.sideBar a[aria-expanded="true"] {
border-right: 5px solid #003E9C;
}
/* nav bar */
.navBarSection .navBar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.navBarSection .navbar-nav{
  width: 100%;
}
.navBarSection .navBar .username{
  font-family:"clash-g-bold";
  color: #091E42;
  font-size: 1.4em;
  line-height: 0.5em;
  margin-top: 1em;
}
.navBarSection .navBar .userScope{
  color: #091E42;
  font-size: 1em;
  font-weight: 400;
}
.navBarSection .navBar .userInfo{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.navBarSection .navBar .searchBox{
  position: relative;
  width: 30em;
}
.navBarSection .navBar .searchBox input{
  width: 100%;
  background: #EBEDF0;
border-radius: 8px;
padding: 0.5em 1em;
padding-left: 3em;
outline: none;
border: none;
}
.navBarSection .navBar .searchBox img{
  position: absolute;
  left: 0.5em;
  top: 50%;
  transform: translateY(-50%);
}
.navbar{
  background: #F6F6F6 !important;
border-bottom: 1px solid #B0B0B0;
}
.mainContent{
  width: calc(100% - 299px);
  background: #F6F6F6
}
.bodyWrapper{
  display: flex;
}
/* cards */
.progressCard{
  border: 0.846505px solid #A6AEBB;
border-radius: 13.5441px;
padding: 0.5em;
background-color: white;
transition: all ease-in-out 200ms;
}
.progressCard:hover{
  box-shadow: 0px 2px 5px 0px black;
}
.progressCard .cardHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3em;

}
.progressCard .cardHeader .date{
  color: #091E42;
  font-size: 0.9em;
  font-weight: 400;
}
.progressCard .cardHeading{
  color: #091E42;
  font-size: 1.2em;
  font-family:"clash-g-bold";
}
.progressCard h6{
  color: #091E42;
  font-size: 0.9em;
  font-weight: 400;
}
.progressCard .userimg{
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.progressCard .cardFooter{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progressCard .cardFooter .daysLable{
  color: #091E42;
  font-size: 9.31155px;
  font-weight: 400;
  background: #E6F0FF;
border-radius: 6.77204px;
padding: 0.5em 1em;
}
.progressCard .dropdown .btn{
  border: none;
  box-shadow: none;
}
.progressCard .progress{
  margin-bottom: 3em;
  background: #BDE4B5;
}
.progressCard .progress-bar{
  background: #005696;
  border-radius: 42.3252px;
}
.progressCard .progressCount{
  display: flex;
  align-items: center;
  justify-content: space-between;
 
}
.progressCard .progressCount h6{
  color: #091E42;
  font-size: 0.9em;
  font-weight: 400;
}
.overview{
  background-color: white;
}
.overviewCard{
    background: #F6F6F6;
    border-radius: 8px;
    padding: 0.5em 1em;
    margin: 1em 0;
}
.overviewCard.charSection .dropdown .btn,.calenderContainer .commonHeader .dropdown .btn,.overviewCard.charSection .dropdown .dropdown-item,.calenderContainer .commonHeader .dropdown .dropdown-item{
  background: #E6F0FF;
border-radius: 8px;
color: black;
border: none;
}
.overviewCard.charSection .dropdown .dropdown-item,.calenderContainer .commonHeader .dropdown .dropdown-item{
  border-radius: 0px;
  border: 1px solid white;
}

.overviewCard.charSection .dropdown .btn img,.calenderContainer .commonHeader .dropdown .btn img{
  margin-left: 5px;
}
.mainHeading{
  color: #091E42;
  font-size: 1.1em;
  font-weight: 600;
  margin: 0.5em 0;
}
/* table component */
.commanTable{
  overflow-x: auto;
}
.commanTable table{
  width: 100%;
  background-color: #ffff;
}
.commanTable table th{
  background: #7AC769;
  padding: 0.5em 0.5em;
  font-weight: 600;
  color: #091E42;
  text-transform: capitalize;
}
.commanTable table th:first-child{
  border-top-left-radius: 8px;
}
.commanTable table th:last-child{
  border-top-right-radius: 8px;
}
.commanTable table td{
  border-bottom: 1px solid #F6F6F6;
  padding: 0.5em 0.5em;
  font-size: 1em;
  font-weight: 400;
  color: #091E42;
  text-transform: capitalize;
}
.commonHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
 margin: 1em 0;
}
.commonBtn{
  color: #091E42;
  font-size: 0.7em;
  background: #E6F0FF;
border-radius: 8px;
padding: 0.5em 1em;
text-decoration: none;
font-weight: 400;
}
.commonBtn span{
  font-size: 1.5em;
  margin-right: 5px;
  font-weight: 600;
}
.testChart{
  width: 100%;
}

/* side bar right */
.rightSideBar{
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
  margin-top: -1em;
  margin-right: -1em;
  padding: 1em;
}
.rightSideBar .dropdown .btn{
  border: none;
  box-shadow: none;
  color: #1A1F36;
  font-weight: 400;
 
}
.rightSideBar .dropdown .btn span{
  color: #7A8699;
  font-size: 13px;
  font-weight: 400;
  margin-right: 1em;
}
 .smText{
  font-size: 0.8em;
  color: #091E42;
  font-weight: 400;
  cursor: pointer;
}
/* notification comp */
.notification {
  background: #E6F0FF;
  padding: 1em;

}
.notification .request{
  box-shadow: 0px 1px 0px #E4E8EE;
  position: relative;
}
.notification .request .user{
  display: flex;
  align-items: center;
  gap: 1em;
}
.notification .request .user .dot{
  position: absolute;
  left: -2px;top: 2px;
}
.notification .request p{
  color: #1A1F36;
  font-size: 0.87;
  font-weight: 600;
}
.notification .request p span{
  font-weight: 400;
}
.notification .request a{
  font-weight: 600;
  border-radius: 4px;
  padding: 0.5em 0.7em;
  text-decoration: none;
  display: inline-block;
  transition: all ease-in-out 200ms;
}
.notification .request a:hover{
  opacity: 0.8;
}
.notification .request a.accept{
  background: #0047B3;
  color: #ffff;
margin-right: 1em;
}
.notification .request a.reject{
  background: #FFFFFF;
  color: #091E42;
}
.exsmText{
  color: #6B788E;
  font-size: 0.7em;
  font-weight: 400;
}
/* contact table */
.commanTable.odd tbody tr:nth-child(even){
  background: #F5F6F7;
}
.commanTable.odd tbody tr:nth-child(odd){
  background: #EBEDF0;
}
.subHeading{
  color: #091E42;
  font-size: 0.9em;
  font-weight: 700;

}
.subHeading span{
  font-weight: 400;
  margin-left: 3px;
}
.sideToggle{
  display: none;
}
/* client data */
.clientData .clientHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0 !important;
}
.clientData .clientHeader p{
  color: #091E42;
  font-size: 1em;
  font-weight: 400;
}
.clientHeader a{
  color: #003E9C;
  font-weight: 600;
}
a{
  text-decoration: none;
}
.clientData{
  min-height: 100vh;
  padding: 1em;
}
.clientData .clientHeader div a:first-child{
  display: inline-block;
  margin-right: 1em;
}
.clientData .addCompany{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.clientData .addCompany h1{
  font-family:"clash-g-bold";
  font-size: 2em;
  color: #091E42;
  font-weight: 600;
  margin-top: 1em;
}
.clientData .addCompany h6{
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 2em;
}
.clientData .mainBtn{
  padding: 0.2em 1em;
}
.clientData.profile .profileCard{
  background: #EFF8ED;
border-radius: 10px;
padding: 2em 1em;
margin-bottom: 3em;
}
.clientData.profile h1{
  font-family:"clash-g-bold";
  font-size: 1.6em;
  color: #091E42;
  margin-bottom: 1em;
}
.clientData.profile h4{
  color: #091E42;
  font-size: 1.1em;
  font-weight: 600;
}
.clientData.profile textarea,.jobTextarea{
  width: 100%;
  resize: none;
  background: #FCFCFC;
border: 1px solid #0047B3;
border-radius: 8px;
padding: 1em;
margin-bottom: 2em;
}
.jobTextarea{
  height: 7em;
  background-color: #F6F6F6;
}
.clientData.profile textarea::placeholder{
  color: #091E42;
  font-weight: 600;
  font-size: 1.1em;
  text-transform: uppercase;
  font-family: 'Archivo';
}
/* lead page */
 .inputHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #B0B0B0;
  padding-bottom: 0.5em;
  margin-bottom: 1.2em;
}
.inputBoxes .inputHeader .dropdown{
  display: inline-block;
}
.inputBoxes .inputHeader .win,.inputBoxes .inputHeader .fail{
  color: #091E42;
  font-size: 0.9em;
  font-weight: 400;
  background: #EFF8ED;
border-radius: 8px;
padding: 0.5em 1em;
margin: 0 7px;
}
.inputBoxes .inputHeader .win img,.inputBoxes .inputHeader .fail img{
  margin-left: 7px;
}
.inputBoxes .inputHeader .fail{
  background: #FFEAEA;
}
.mainBtn.white{
  color: #0047B3 !important;
  background: #FFFFFF;
border: 2px solid #0047B3;
border-radius: 4px;
padding: 0.3em 1em;
}
.inputBoxes .mainHeading{
  font-family:"clash-g-bold";
}
.leadSelect,.leadInput{
  background: #F6F6F6;
border: 1px solid #0047B3;
border-radius: 8px;
display: block;
width: 100%;
padding: 0em 1em;
height: 2.5em;
margin-bottom: 2em;
}
.selectOption .dropdown .btn{
  background: #F6F6F6;
  border: 1px solid #0047B3;
  border-radius: 8px;
  display: block;
  width: 100%;
  padding: 0em 1em;
  height: 3em;
  color: #7A8699;
  text-align: start;
}
 .dropdown .btn::after{
  display: none;
}
 .dropdown .dropdown-menu.show{
  width: 100%;
}
.selectOption.dark .btn{
  color: #091E42;
  border: 1.6px solid #B0B0B0;
border-radius: 8px;
height: 2.5em;
}
.selectOption.dark .btn img{
  margin-left: 5px;
}
.leadSelect,.leadInput::placeholder{
  color: #7A8699;
  font-size: 1.1em;
  font-weight: 400;
}
.dropdown .dropdown-item{
  color: #091E42;
}
.dropdown .dropdown-item:hover{
  color: #003E9C;
}
.dropdown .btn .dropDownArrow{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown .btn.show .dropDownArrow img{
  transform:rotate(180deg)
}
.leadSelect option{
  color: black;
}
.leadSelect{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("./assets/sdown.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}
.uploadBox{
  background: #FFFFFF;
border-radius: 16px;
padding: 1.5em;
}
.uploadBox .upload{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px dashed #D0D5DD;
border-radius: 4px;
padding: 1em;
cursor: pointer;
}
.mainText{
  color: #091E42;
font-weight: 400;
font-size: 1em;
}
#uploadFile{
  display: none;
}
.boxSection{
  margin-bottom: 2em;
}
.inputBoxes label{
  margin-bottom: 0.7em;
}
/* lead list table */
.commanTable table td .dropdown{
  display: inline-block;
}
.commanTable .resp{
  padding: 0.8em 0.8em;
  font-size: 11px;
  font-weight: 400;
  color: #091E42;
  margin: 0 5px;
  border-radius: 8px;
}
.commanTable .resp img{
  margin-left: 5px;
}
.commanTable .resp.silent{
  opacity: 0.4;
}
.commanTable .resp.gray{
  background: #DFE2E6;
}
.commanTable .resp.lost{
  background: #FEAAAA;
}
.commanTable .resp.win{
  background: #BDE4B5;
}
.clientData table thead th:first-child{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.clientData table thead th:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.paginationBox{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15em;
}
.pagination .page-item{
  margin: 0 0.3em;
}
.pagination .page-item .page-link{
  color: #091E42;
  font-weight: 700;
  border-radius: 4px;
}
.pagination .page-item.active .page-link{
  background: #FFFFFF;
  border: 1px solid #0047B3;
  border-radius: 4px;
  color: #0047B3;
}
/* hr table */
.commanTable.hrTable table td:first-child img,
.commanTable.candidate table td:first-child img,
.commanTable.payrollTable table td:first-child img
{
margin-right: 10px;
}
.commanTable.candidate tr td:last-child img,
.commanTable.payrollTable tr td:last-child img
{
  margin-left: 1em;
}

.clientData.hrDashboard .sortSelect .selectOption{
  display: flex;
  align-items: center;
  justify-content: flex-end;
 margin-top: 2em;
 margin-bottom: 2em;
}
.clientData.hrDashboard .sortSelect label{
  display: inline-block;
  /* margin-top: -2em; */
  margin-right: 1em;
}
.clientData.hrDashboard .sortSelect select{
  width: 15em;
}
.sortSelect{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  margin-bottom: 1em;
}
.sortSelect .filter{
  background: #FFFFFF;
border: 1px solid #0047B3;
border-radius: 8px;
padding: 1em 0.5em;
width: 25em;
}
.sortSelect .filter span{
  color: #091E42;
  font-weight: 400;
  background: #E6F0FF;
border-radius: 8px;
padding: 0.5em;
margin: 0 7px;
cursor: pointer;
}
.clientData.profile .userTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clientData.profile .smText{
  text-transform: uppercase;
}
/* hr employ  */
.mainBtn.red{
  border: 2px solid #B12222;
  color: #B12222 !important;
  background-color: transparent;
}
/* attendence */
.commanTable.attendence tr td:last-child img,
.commanTable.acc tr td:last-child img ,
.commanTable.subs tr td:last-child img
{
  margin: 0 7px;
}
.commanTable.attendence tr td:last-child img:hover,.commanTable.acc tr td:last-child img:hover {
  opacity: 0.7;
}
.attendDate {
  display: flex;
  align-items: center;
  gap: 2em;
  padding-left: 5em;
  margin: 1em 0;
}
.attendDate .dateInp{
  position: relative;
}
.attendDate .dateInp img{
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.attendDate input{
  width: 100%;
  background: #F6F6F6;
border: 1px solid #0047B3;
border-radius: 8px;
padding: 0.5em 2em;
padding-right: 0em;
color: #7A8699;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none; */
    /* -webkit-appearance: none; */
    opacity: 0;
    width: 2em;
    z-index: 999;
}
.emptyTable {
   text-align: center;
   background-color: white;
   padding: 1em 0;
}

.MakePayment{
  background: #E6F0FF;
border-radius: 8px;
padding: 0.5em;
color: #091E42;
}
.MakePayment.silent{
  opacity: 0.5;
}
.payroll{
  position: relative;
}
.payroll .nav-tabs{
  position: absolute;
  top: 12em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 97%;
}
.payrollTable table{
  margin-top: 7em;
}
.payrollTable.payslip table{
  margin-top: 3.5em;
}
.clientData.payroll .nav-tabs .nav-link{
  background: #FFFFFF;
  border-radius: 0;
  /* border: 0.5px solid #C4C4C4; */
  color: #7A8699;
  font-weight: 400;
}
.clientData.payroll .nav-tabs .nav-link.active{
  background: #E6F0FF;
  color: #003E9C;
border-top: 2px solid #003E9C;
}
.commanTable.payrollTable table thead th,.commanTable.acc table thead th{
  border-radius: 0;
}

.commanTable.acc .totalDetail{
  text-align: center;
  background: #E6F0FF;
border: 1px solid #2B7FFF;
padding: 1em 0;
}
.commanTable.acc .totalDetail.red{
  background: #FFEAEA;
border: 1px solid #FD3030;
}

.substitudeCard{
  text-align: center;
  background: #EFF8ED;
border: 1px solid #5FBC4A;
border-radius: 16px;
padding: 1em 0;
margin-bottom: 1em;
}
.substitudeCard.blur{
  background: #E6F0FF;
  border: 1px solid #2B7FFF;
}
.substitudeCard.blur h1{
  filter: blur(7.6px);
  font-weight: 700;
}
.substitudeCard.red{
  background: #FFEAEA;
border: 1px solid #FD3030;
}
.substitudeCard .mainText{
  margin-bottom: 1em;
}
.substitudeCard h1{
  color: #091E42;
  font-weight: 400;
  font-size: 2.5em;
}
/* offcanvas */
.offcanvas.show:not(.hiding), .offcanvas.showing {
  width: 40em;

  background: #F6F6F6;
}
.offcanvas-header{
  background: #7AC769;
  color: #091E42;
}
.offcanvas-title{
  font-weight: 600;
  font-size: 1.5em;
}
.popUpTextArea{
background: #FCFCFC;
border: 1px solid #0047B3;
border-radius: 8px;
width: 100%;
padding: 1em;
height: 7em;
resize: none;
}
.dateHr input,.commanInput{
  background: #FDFDFD;
border: 1px solid #003E9C;
border-radius: 8px;
width: 100%;
padding: 0.5em;
}
.commanInput{
  width: initial;
}
.guest{
  background: #E6F0FF;
border-radius: 8px;
padding: 5px 10px;
color: #091E42;
font-weight: 400;
margin-right: 5px;
cursor: pointer;
display: inline-block;
margin-top: 0.5em;
}
.guest img{
  margin-right: 3px;
}
.meetings{
  margin-top: 1em;
}
.meetings a{
  border-radius: 4px;
  padding: 3px 1em;
  background: #0066DA;
  color: white;
  font-weight: 400;
  display: inline-block;
  margin-left: 0.5em;
}
.meetings a:nth-last-of-type(2){
  background: #7B83EB;
}
.meetings a:nth-last-of-type(3){
  background: #0047B3;
}
.mainBtn.green{
  background: #7AC769;
  color: #091E42 !important;
  border-color: #7AC769;
}
.genders span{
  margin-left: 0.3em;
  color: #091E42;
  font-size: 1.1em;
  font-weight: 600;
}
.dropdown-menu{
  border-radius: 0;
  padding: 0;
  border: 0;
}

.dropdown-item{
  padding: 1em 0.5em;
}
.dropdown-item img{
  margin-right: 1em;
}
.dropdown-item.deleteOption{
  background: #FFEAEA;
  color: #FD5353;
}
.carouselBox{
  padding: 2em 0;
  position: relative;
}
.carouselItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.dateBox{
  background: #091E42;
border-radius: 16.1611px;
padding: 0.5em;
width: 5em;
margin: 0 2px;
position: relative;
}
.dateBox span{
  display: inline-block;
  position: absolute;
  bottom: 5px;
  right: 7px;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: #5FBC4A;
}
.dateBox.thu{
  background: #5FBC4A;
}
.dateBox.thu span{
  background-color: white;
}
.dateBox .mainText{
  font-size: 0.8em;
  color: white;
}
.monthSeclect{
  background-color: transparent;
  border:none;
}
.carousel-control-next-icon{
  background-image: url("./assets/next.svg");
  position: absolute;
  right: 0;
  opacity: 1 !important;
}
.carousel-control-prev,.carousel-control-next{
  position: relative;
  width: 100%;
  top: -6em;
 
}
.carousel-control-prev-icon{
  background-image: url("./assets/prev.svg");
  position: absolute;
  right: 3em;
  opacity: 1 !important;
 
}
.carousel-indicators [data-bs-target]{
  display:none;
}
select option{
  background-color: white;
  border: none;
  box-shadow: none;
}
.mainBtn.modalBtn{
  background: #091E42;
  color: white;
  border: 1px solid #DFE2E6;
}
.backBtnBox{
  display: flex;
  align-items: center;
  gap: 1em;
}
.pyrollInputs{
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  gap: 1em;
}
.mainBtn.back{
  width: 10em;
  background: #F5F6F7;
border: 1px solid #DFE2E6;
color: #091E42 !important;
}
.mainBtn.back img{
  margin-right: 0.5em;
}
.checkBoxes input{
margin-right: 1em;
}
.commanTable{
  overflow-x: hidden;
}
.hrDashboard .selectOption .dropdown .btn{
  width: 13em;
}
/* calender */
.react-calendar{
  background-color: white;
}
.react-calendar__month-view__days button{
  padding-bottom: 2em;
  text-align: end;
  padding-right: 5px;
  padding-top: 5px;
  border: 1px solid #DFE2E6;
  background-color: white;
}
.react-calendar__month-view__days button:hover{
  background: #E6F0FF;
}
.react-calendar__month-view__weekdays{
  background: #7AC769;
border: 1px solid #C4C4C4;
}
.react-calendar__month-view__weekdays__weekday{
  text-align: end;
  padding: 1em 5px;
}
.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none !important;
}
.react-calendar__navigation{
  text-align: center;
  padding: 1em 0;
  position: relative;
}
.react-calendar__navigation button{
  font-size: 1.5em;
  background-color: transparent;
  border: none;
  margin: 0 1em;
  position: relative;
}

.react-calendar__navigation__prev2-button,.react-calendar__navigation__next2-button{
  display: none;
}
.calenderContainer{
  position: relative;
  background-color: white;
  padding: 2em;
}
.calenderContainer .commonHeader .dropdown{
  position: absolute;
  top: 4em;
  right: 2em;
  z-index: 2;
}
.calenderContainer .commonHeader span a{
  z-index: 2;
  background: #F5F6F7;
  border: 1px solid #DFE2E6;
  border-radius: 7px;
  color: black;
  padding: 0.5em 1em;
  display: inline-block;
  position: absolute;
  top: 4em;
}
.weekCalender table{
  width: 100%;
}
.weekCalender table th{
  text-align: center;
}
.weekCalender table td{
  border: 1px solid #DFE2E6;
  width: 5em;
  padding: 0.5em 0.2em;
}
.weekCalender table td.green{
  background: #7AC769;
  font-weight: 400;
font-size: 11px;
padding-left: 5px;
}
.weekCalender table td:first-child,.weekCalender table th:first-child{
  text-align: end;
  border-left: none;
  color: #8993A4;
  font-size: 13px;
  font-weight: 400;
}
.weekCalender.day table th:last-child{
  width: 90%;
  text-align: start;
  padding: 0 2em;
}
.weekCalender .commonHeader .today a{
  background: #F5F6F7;
border: 1px solid #DFE2E6;
border-radius: 7px;
padding: 0.5em 1em;
color: black;
}
.changeDate{
  color: #091E42;
  font-size: 1.2em;
  font-weight: 600;
}
.changeDate img{
  margin: 0 1em;
  cursor: pointer;
}
.weekCalender .commonHeader .dropdown .btn{
  background: #E6F0FF;
border-radius: 8px;
border: none;
color: black;
}
.weekCalender .commonHeader .dropdown .btn img{
  margin-left: 5px;
}
.weekCalender .commonHeader .dropdown .dropdown-item{
  background: #E6F0FF;
  color: black;
  border: 1px solid white;
}
.weeklyCalender{
  background-color: white;
  padding: 2em;
}
.toastContainer{
  position: relative;
  width: 50%;
  text-align: end;
}
.toastContainer .toast{
  position: absolute;
  right: 0;
  top: 3.5em;
  background: #EAF2E9;
  border: 1px solid #1E4C14;
  border-radius: 16px;
  overflow: hidden;
  text-align: start;
  font-weight: 400;
font-size: 0.8em;
color: #091E42;

}
.toast-header{
  background: #EAF2E9;
  border: none;
  font-weight: 600;
font-size: 1em;
line-height: 120%;
color: #317D20;
font-family: 'Archivo';
}

.attendance .nav-tabs{
  border: none;
}
.attendance .nav-tabs .nav-link{
background-color: white;
color: #091E42;
border-radius: 0;
}
.attendance .nav-tabs .nav-link.active{
  background: #E6F0FF;
border-top: 2px solid #003E9C;
color: #003E9C;
}

.printCard{
  background: #FFFFFF;
border-radius: 24px;
padding: 1em;
}
.printCard .printCardHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #B0B0B0;
  margin-bottom: 1em;
}
.printCard h1{
  color: #003E9C !important;
  font-weight: 600 !important;
  font-size: 1em !important;
}
.printCard .mainText{
  font-weight: 400;
  line-height: 19px;
}
/* media queryy */
@media(max-width:992px){
  .loginPage form .mainBtn{
    margin-top: 2em;
  }
  .payroll .nav-tabs{
    top: 12em;
  }
  .payrollTable table{
    margin-top: 7em;
  }
  .payrollTable.payslip table{
    margin-top: 3.5em;
  }
  .rightSideBar{
    margin: 0;
  }
  .clientHeader.substitute{
    flex-direction: column;
    align-items: flex-start;
  }
  .attendDate.monthly, .attendDate.summary{
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar{
    z-index: 999999999;
   
  }
  .attendDate {
  display: flex;
  align-items: center;
  gap: 1em;
  padding-left: 0em;
}
  .clientData.profile .smText{
    margin-top: 2em;
  }
  .inputBoxes .inputHeader{
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .mainBtn.white{
    font-size: 0.6em;
  }
  .inputBoxes .inputHeader .win, .inputBoxes .inputHeader .fail{
    font-size: 0.7em;
  }
  .inputBoxes .inputHeader .win img,.inputBoxes .inputHeader .fail img{
    margin-left: 2px;
  }
  .progressCard{
    margin-bottom: 1em;
  }
  .loginPage form{
    padding: 5em 1em;
    height: 70vh;
  }
  .sideBar{
    position: absolute;
    top: 4.2em;
    left: -500px;
    z-index: 99999;
    transition: all ease-in-out 400ms;
  }
  .mainContent{
    width: 100%;
  }
  .commanTable{
    overflow-x: auto;
  }
  .commanTable table{
    width: 1000px;
  }
  .navbar{
    position: relative;
    padding: 2em 0;
  }
  .navbar .navbar-toggler{
    position: absolute;
    right: 1em;
    top:12px;
  }
  .navBarSection .navBar{
    flex-direction: column;
    align-items: start;
  }
  .navBarSection .navBar .searchBox {
    margin: 2em 0;
  }
  .navBarSection .navBar .searchBox input{
    width: 70%;
  }
  .sideToggle{
    display: inline-block;
    width: 2em;
    top: 12px;
    left: 1em;
    position: absolute;
  }
  .show{
    left: 0;
  }
}
@media (min-width: 1400px){
  .container{
    max-width: 100vw;
  }
}
